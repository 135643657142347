import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import Cookies from 'js-cookie';
import { LoginResponseData, User } from '../../types/general';
import { accessTokenCookieName } from '../../hooks/useUser';

type UserState = {
  token: string | undefined;
  user: User | undefined;
};

const initialState: UserState = {
  token: Cookies.get('AccessToken'), // тут багует на импорт
  user: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>,) {
      state.user = action.payload;
    },
    setUserAndToken(state, action: PayloadAction<LoginResponseData>,) {
      const { token, user } = action.payload;
      state.token = token;
      state.user = user;
      // cookie ставит бэк
    },
    logoutUser(state) {
      Cookies.remove(accessTokenCookieName);
      state.token = undefined;
      state.user = undefined;
    }
  },
  extraReducers: {},
});
export const { setUserAndToken, setUser, logoutUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectUserAccessToken = (state: RootState) => state.user.token;
