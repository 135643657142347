import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  Label,
  TextareaField,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { PhoneInput } from "../PhoneInput/PhoneInput";
import styles from "./Checkout.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/useApp";
import {
  cleanCart,
  selectCartItems,
  selectCartItemsTotalAmount,
} from "../../store/slices/cart";
import { formatPrice } from "../../utils/utils";
import { api } from "../../api/api";
import { AxiosError } from "axios";

export const errorMessage = "Это поле обязательно для заполнения";
const labelProps = {
  style: { marginBottom: 8, display: "block" },
};

export type CheckoutFormInputs = {
  name: string;
  organization: string;
  phone: string;
  comment: string;
  pickupStore: string;
};


type AddParameters<
  TFunction extends (...args: any) => any,
  TParameters extends [...args: any]
  > = (
  ...args: [...Parameters<TFunction>, ...TParameters]
) => ReturnType<TFunction>;


type SubmitType = AddParameters<SubmitHandler<CheckoutFormInputs>, [isWidget: boolean]>; //SubmitHandler<CheckoutFormInputs> & {};


export const CheckoutForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const totalAmount = useAppSelector(selectCartItemsTotalAmount);
  const cartItems = useAppSelector(selectCartItems);

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm<CheckoutFormInputs>({
    defaultValues: {

    },
  });


  // <div class={"catalog"}>
  //   <div classs={"catalog-header"}>
  //     <Tab name="featured" />
  //     <Tab name="community" />
  //     <DesktopFilters /> === display: none, @include desktop { display: block }
  //   </div>
  //   <div class"catalog-ittems">
  //     {map.products...}
  //     <MobileFiltersTrigger />
  //     display:block, @include desktop { display: none; }. это только триггер, он должен вызвать модалку (PortalModal(<ComponentName />);
  //     причем в модалке должны быть теже фильтры что и в десктоп
  //   </div>
  // </div>


  const onSubmit: SubmitType = (data, event, isWidget) => {
    const positions = cartItems;
    api
      .post(`/orders`, {
        ...data,
        positions,
      })
      .then((response) => {
        toaster.success("Заказ оформлен");

        if(isWidget) {
          const orderCode = response.data.object.orderCode;
          if(orderCode) {
            // @ts-ignore
            // window.showVtbAcqWidget(orderCode);
            window.showVtbAcqWidget({ orderId: orderCode });
          }
        } else {
          if(response.data.object.payUrl) {
            // document.location = response.data.object.payUrl.replace('http', 'https');
            window.location.href = response.data.object.payUrl;
          } else {
            alert("нету payUrl")
          }
        }

        dispatch(cleanCart());
      })
      .catch((error: AxiosError<{ message?: string }>) => {
        toaster.danger(error.response?.data?.message || 'Призошла ошибка');
      });
  };



  const submit = handleSubmit(data => onSubmit(data, undefined, false));
  const submitWithWidget = handleSubmit(data => onSubmit(data, undefined,true));

  return (
    <>
      <form className="row" onSubmit={(e) => e.preventDefault()}>
        <div className="col-xs-12 col-md-6">
          <TextInputField
            label="Получатель"
            required
            isInvalid={!!errors?.name}
            validationMessage={errors?.name && errorMessage}
            {...register("name", { required: true })}
          />
        </div>

        <div className="col-xs-12 col-md-6">
          <Label {...labelProps}>Телефон *</Label>
          <Controller
            control={control}
            name="phone"
            rules={{
              validate: (v: string) => {
                const value = v ? v.replace(/\D/g, "") : "";
                return value.length === 11;
              },
            }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                isInvalid={!!errors?.phone}
                validationMessage={errors?.phone && errorMessage}
              />
            )}
          />
          <div style={{ height: 24 }} />
        </div>


        <div className="col-xs-12">
          <TextareaField
            label="Комментарий к заказу"
            {...register("comment")}
          />
        </div>

        <div className="col-xs-8">
          <Button onClick={submit} appearance="primary">
            Оформить заказ (pay.vtb.ru)
          </Button>
          {window.location.search.includes('showWidgetBtn=1') ? (
            <Button onClick={submitWithWidget} style={{ marginLeft: 16 }}>
              Оформить заказ (widget)
            </Button>
          ) : null}
        </div>
        <div className="col-xs-4">
          <div className={styles.TotalAmount}>
            Итого: {formatPrice(totalAmount)}
          </div>
        </div>
      </form>
    </>
  );
};
