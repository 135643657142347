import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { api } from "../../api/api";
import { City, Store } from "../../types/general";

type ConfigState = {
  stores: Store[];
  categories: {
    id: string;
    name: string;
  }[];

  city: City | undefined;
  cities: City[];
  organization?: {
    acceptOrdersFrom: string;
    address: string;
    phone: string;
    'telegram': string;
    'whatsapp': string;
  } | undefined;
  isShownCheckout: boolean;
};

const initialState: ConfigState = {
  stores: [],
  categories: [
    { id: "b0df740d-7b14-11ea-0a80-00080004dcc7", name: "Табак" },
    { id: "9ff8665f-a949-11ea-0a80-06e6000dbb0a", name: "Смеси" },
    { id: "357f7806-eb42-11e9-0a80-0048001ca65c", name: "Кальяны" },
    { id: "54211b57-830c-11ea-0a80-004a00256c22", name: "Комплектующие" },
    { id: "8a7b5adf-eb48-11e9-0a80-03b7001e8e72", name: "Аксессуары" },
    { id: "03860b89-db42-11e8-9ff4-34e80016c179", name: "Уголь" },
    { id: "cb28c247-ed88-11e8-9107-504800083f68", name: "Культ" },
    { id: "8fb67598-afe7-11ea-0a80-00fa000daee4", name: "Электронные испарители" },
  ],
  city: undefined,
  cities: [],
  isShownCheckout: false,
  organization: undefined,
};

// дефолтная категория для загрузки каталога
export const initialCategoryId = initialState.categories[0].id;

export const fetchStores = createAsyncThunk("config/fetchStores", () => {
  return api.get(`/stores`).then((response) => response.data);
  // .catch((error) => error);
});

export const fetchCities = createAsyncThunk("config/fetchCities", () => {
  return api.get(`/cities`).then((response) => response.data);
  // .catch((error) => error);
});

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    toggleCheckoutDialog(state, action) {
      state.isShownCheckout = action.payload;
    },
  },
  extraReducers: {
    [fetchStores.fulfilled.type]: (state, action) => {
      state.stores = action.payload;
    },
    [fetchCities.fulfilled.type]: (state, action) => {
      state.city = action.payload.current;
      state.cities = action.payload.cities;
      state.organization = action.payload.currentOrganization;
    },
  },
});

export const { toggleCheckoutDialog } = configSlice.actions;

export const selectStores = (state: RootState) => state.config.stores;
export const selectCategories = (state: RootState) => state.config.categories;
export const selectCity = (state: RootState) => state.config.city;
export const selectCities = (state: RootState) => state.config.cities;
export const selectOrganization = (state: RootState) => state.config.organization;
export const selectisShownCheckout = (state: RootState) =>
  state.config.isShownCheckout;
