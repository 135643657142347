import React, { useState } from 'react';
import MaskedInput, { conformToMask } from 'react-text-mask';
import styles from '../LoginDialog/LoginDialog.module.scss';
import { FormFieldValidationMessage, InlineAlert } from 'evergreen-ui';

export const mask = '+7 (___) ___ __ __';
const maskArray = [
  '+',
  '7',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
];

type Test = {
  rawValue: string;
};

type Props = {
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  name: string;
  isInvalid?: boolean;
  validationMessage?: string | undefined;
  value?: string;
  autoFocus?: boolean;
};

export const PhoneInput: React.FC<Props> = (props) => {
  const [skipFirst, setSkipFirst] = useState(false);
  const pipe = (conformedValue: string, { rawValue }: Test) => {
    let value = conformedValue;
    const firstChar = rawValue.charAt(0);
    const char4 = conformedValue.charAt(4);
    const char5 = conformedValue.charAt(5);
    const countChars = rawValue.length;

    // если пустой инпут при ручном вводе
    if (char4 !== '_' && char5 === '_') {
      if ((char4 === '7' || char4 === '8') && !skipFirst) {
        setSkipFirst(true);
        value = mask;
      }
    } else if (char4 === '_' && char5 === '_' && skipFirst) {
      setSkipFirst(false);
    }

    // логика при вставке/автофилле
    if (firstChar === '8' && countChars === 11) {
      value = conformToMask(
        `+7` + rawValue.substr(1),
        maskArray,
      ).conformedValue;
    } else if (firstChar === '7' && countChars === 11) {
      value = conformToMask(`+` + rawValue, maskArray).conformedValue;
    }

    return value
  };

  return (
    <>
    <MaskedInput
      className={styles.PhoneInput}
      placeholder={mask}
      keepCharPositions={true}
      mask={maskArray}
      pipe={pipe}
      data-isInvalid={props.isInvalid}
      // required={true}
      {...props}
    />

      {props.isInvalid ? <FormFieldValidationMessage marginTop={8}>{props.validationMessage}</FormFieldValidationMessage> : null}
    </>
  );
};
