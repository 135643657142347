import { Product as ProductType } from '../../types/general';
import React from 'react';
import styles from './CartProduct.module.scss';
import { ProductQty } from '../ProductQty/ProductQty';
import ProductGallery from '../Product/ProductGallery';
import { formatPrice, randomIntFromInterval } from '../../utils/utils';
import { Button } from "evergreen-ui";

type Props = {
  data: ProductType;
};

const CartProductComponent: React.FC<Props> = ({ data }) => {
    // console.log('render ProductComponent');
    //{randomIntFromInterval(10,5000)}
  return (
    <div className={styles.CartProduct}>
      <div className={styles.ImageWrapper}>
        <ProductGallery images={data?.images || []} name={data?.name} />
      </div>
      <div className={styles.Info}>
        <div className={styles.Name} title={data?.name}>
          {data?.name}
        </div>
        <div className={styles.Stock}>
          {/* В наличии: {data.sum_stocks_in_city} шт. */}
        </div>
        <div className={styles.Price}>{formatPrice(data?.price)}</div>
        <div className={styles.Qty}>
          <ProductQty id={data.id} data={data} />
        </div>
      </div>
    </div>
  );
};

export const CartProduct = React.memo(CartProductComponent);
