import React, { useEffect, useState } from 'react';
import styles from './ProductQty.module.scss';
import { IconButton, MinusIcon, PlusIcon, TextInputField } from 'evergreen-ui';
import { Product } from '../../types/general';
import { useAppDispatch, useAppSelector } from '../../hooks/useApp';
import { changeCartItemCount, selectCartItems } from '../../store/slices/cart';

type Props = {
  id: string;
  data: Product;
};

const ProductQtyComponent: React.FC<Props> = ({ id, data }) => {
  const dispatch = useAppDispatch();
  const itemInCart = useAppSelector((state) =>
    state.cart.items.find((item) => item.id === id),
  );
  const value = itemInCart?.count | 0;

  const changeValue = (amount: number) => {
    if(amount < 0) return;
    dispatch(
      changeCartItemCount({
        ...data,
        count: amount,
      }),
    );
  };

  return (
    <div className={styles.ProductQty}>
      <IconButton icon={MinusIcon} onClick={() => changeValue(value - 1)} />
      <TextInputField
        inputWidth={60}
        type="number"
        value={value}
        min={0}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeValue(parseInt(e.target.value))}
      />
      <IconButton icon={PlusIcon} onClick={() => changeValue(value + 1)} />
    </div>
  );
};

export const ProductQty = React.memo(ProductQtyComponent);
