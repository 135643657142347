import { Product as ProductType } from '../../types/general';
import React from 'react';
import styles from './Product.module.scss';
import { ProductQty } from '../ProductQty/ProductQty';
import ProductGallery from './ProductGallery';
import { formatPrice, randomIntFromInterval } from '../../utils/utils';
import { Button } from "evergreen-ui";
import { useAppDispatch, useAppSelector } from "../../hooks/useApp";
import { changeCartItemCount, selectCartItems } from "../../store/slices/cart";

type Props = {
  data: ProductType;
};

const ProductComponent: React.FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector(selectCartItems);
  const itemInCart = cartItems.find((item) => item.id === data.id);

  const addToCart = () => {
    dispatch(
      changeCartItemCount({
        ...data,
        count: itemInCart ? itemInCart.count+1 : 1,
      }),
    );
  }

  return (
    <div className={styles.Product}>
      <div className={styles.ImageWrapper}>
        <ProductGallery images={data?.images || []} name={data?.name} />
      </div>
      <div className={styles.Info}>
        <div className={styles.Name} title={data?.name}>
          {data?.name}
        </div>
        <div className={styles.Price}>{formatPrice(data?.price)}</div>
        <Button  appearance="primary" onClick={addToCart}>{itemInCart ? `В корзине (${itemInCart.count})` : 'В корзину'}</Button>
      </div>
    </div>
  );
};

export const Product = React.memo(ProductComponent);
