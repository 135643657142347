import axios, { AxiosRequestConfig, HeadersDefaults } from 'axios';
import Cookies from 'js-cookie';
import { accessTokenCookieName } from '../hooks/useUser';

export let baseURL = 'http://localhost:3010';
if (process.env.NODE_ENV === 'production') baseURL = '/api'

export const api = axios.create({ baseURL });

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const accessToken = Cookies.get(accessTokenCookieName);
  if(accessToken && config.headers) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  config.withCredentials = true;

  return config;
}, error => {
  return Promise.reject(error)
});
