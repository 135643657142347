import React, { FC } from 'react';
import styles from './TestLayout.module.scss';
import {Header, HeaderMobile} from '../Header/Header';
import {Footer} from '../Footer/Footer';
import {Catalog} from '../Catalog/Catalog';
import {SidebarMenu} from '../SidebarMenu/SidebarMenu';
import {SidebarCart} from '../SidebarCart/SidebarCart';
import {useAppSelector} from '../../hooks/useApp';
import {selectisShownCheckout} from '../../store/slices/config';
import {Checkout} from '../Checkout/Checkout';
import { Product } from "../Product/Product";
import { selectProducts } from "../../store/slices/catalog";


export const TestLayout: React.FC = () => {
  const { data} = useAppSelector(selectProducts);
  return (
    <div className={styles.Layout}>
      <div className={styles.Products}>
        {data.map(product => <Product data={product} />)}
      </div>
      <Checkout />
    </div>
  );
};
