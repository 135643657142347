import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { Product } from '../../types/general';
import { baseURL } from '../../api/api';

type Props = {
  images: Product['images'];
  name: Product['name'];
};

const ProductGallery: React.FC<Props> = ({ images, name }) => {
  const [toggler, setToggler] = useState(false);
  const imgSrc = images.length
    ? images[0].preview
    : 'nophoto.png';
  const sources = images.map((image) => image.url);
  return (
    <>
      <img
        src={imgSrc}
        alt={name}
        onClick={() => setToggler(!toggler)}
        style={{ cursor: 'pointer' }}
      />
      {images.length ? (
        <FsLightbox
            toggler={toggler}
            sources={sources}
        />
      ) : null}
    </>
  );
};

export default ProductGallery;
