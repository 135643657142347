import React from "react";
import { useAppSelector } from "../../hooks/useApp";
import { selectCartItems } from "../../store/slices/cart";
import { Heading, Text } from "evergreen-ui";
import { CheckoutForm } from "./CheckoutForm";
import styles from "./Checkout.module.scss";
import { CartProduct } from "../CartProduct/CartProduct";

export const Checkout: React.FC = () => {
  const cartItems = useAppSelector(selectCartItems);
  if (!cartItems.length)
    return null; //<Text style={{ margin: "auto" }}>Корзина пуста</Text>;
  return (
    <>
      <Heading marginBottom={32}>Корзина</Heading>
      <div className={styles.CartItems}>
        {cartItems.map((item) => (
          <CartProduct key={item.id} data={item} />
        ))}
      </div>
      <div style={{ height: 60 }} />
      <Heading marginBottom={32}>Оформление заказа</Heading>
      <CheckoutForm />
      <div style={{ height: 60 }} />
    </>
  );
};
