export const formatPrice = (value: number | undefined) => {
    return (value ? value.toLocaleString() : 0) + ' ₽';
}

export const formatPhone = (value: string) => {
    return value.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 ($2) $3 $4 $5");
}

export function randomIntFromInterval(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

//declOfNum(count, ['найдена', 'найдено', 'найдены']);
export function declOfNum(number: number, titles: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}


export const isMobile = window.innerWidth <  1024;
