import { configureStore } from '@reduxjs/toolkit';
// import { counterSlice } from './slices/cart';
import { catalogSlice } from './slices/catalog';
import { configSlice } from './slices/config';
import { cartSlice } from './slices/cart';
import { userSlice } from './slices/user';
// ...

export const store = configureStore({
  reducer: {
    catalog: catalogSlice.reducer, // переписаь его нахуй. он тупит
    config: configSlice.reducer,
    cart: cartSlice.reducer,
    user: userSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AsyncThunkState<T> = {
  isLoading: boolean;
  data: T;
  error: object;
};

export const AsyncThunkInitialState = <T>(data: T): AsyncThunkState<T> => ({
  isLoading: false,
  data,
  error: {},
});