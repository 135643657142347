import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Product, Store } from '../../types/general';

type CartState = {
  items: any[];
};

const initialState: CartState = {
  items: [
   /* {
      id: '5049eff7-1e5e-11e9-9ff4-34e8000a4661',
      name: 'КальянBigMaksHookahOne',
      type: 'product',
      folder: {
        id: '3657530d-1e5d-11e9-9ff4-3150000ab354',
        name: 'Big Maks',
      },
      images: [
        {
          url: '/images/e097e402-80ca-4d4d-8557-39bda1dbcd26',
          preview: '/images/e097e402-80ca-4d4d-8557-39bda1dbcd26/preview',
        },
      ],
      stocks: {
        '3110b9bd-c5d0-11ea-0a80-02af000b93a2': 1,
        '5867a84a-c5d0-11ea-0a80-0059000c0a3f': 1,
        'c97ce7ba-a969-11ea-0a80-02b30011b80b': 1,
      },
      sum_stocks_in_city: 0,
      price: 7500,
      count: 1,
    },
    {
      id: '7745b30e-1c69-11ec-0a80-0992000d161a',
      name: 'КальянBigMaksHookahOneMini',
      type: 'product',
      folder: {
        id: '3657530d-1e5d-11e9-9ff4-3150000ab354',
        name: 'Big Maks',
      },
      images: [
        {
          url: '/images/c40826f1-b4e1-4ff4-a133-ddf11e46851a',
          preview: '/images/c40826f1-b4e1-4ff4-a133-ddf11e46851a/preview',
        },
      ],
      stocks: {
        'cf1bd378-a969-11ea-0a80-01220012f5f9': 1,
      },
      sum_stocks_in_city: 0,
      price: 4100,
      count: 1,
    }

    */
  ],
};

export const cartSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    cleanCart(state) {
      state.items = [];
    },
    changeCartItemCount(
      state,
      action: PayloadAction<Product & { count: number }>,
    ) {
      const cartProductIndex = state.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      const { count } = action.payload;
      if (cartProductIndex < 0) {
        // create
        if(count > 0) state.items.push(action.payload);
      } else {
        if (count < 1) {
          // delete
          state.items.splice(cartProductIndex, 1);
        } else {
          // update
          state.items[cartProductIndex] = action.payload;
        }
      }
    },
  },
  extraReducers: {},
});
export const { changeCartItemCount, cleanCart } = cartSlice.actions;
export const selectCartItems = (state: RootState) => state.cart.items;
export const selectCartItemsTotalCount = (state: RootState) =>
  state.cart.items.map((item) => item.count).reduce((a, b) => a + b, 0);
export const selectCartItemsTotalAmount = (state: RootState) =>
  state.cart.items
    .map((item) => item.count * item.price)
    .reduce((a, b) => a + b, 0);
