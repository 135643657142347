import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./useApp";
import { logoutUser, selectUser, selectUserAccessToken, setUser, setUserAndToken, } from "../store/slices/user";
import { api } from "../api/api";
import { LoginResponseData, User } from "../types/general";
import { AxiosResponse } from "axios";

type Test = {
  phone: string;
  code: string;
};

export const accessTokenCookieName = "AccessToken";

export const useUser = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectUserAccessToken);
  const user = useAppSelector(selectUser);

  const fetchUser = useCallback(async () => {
    api
      .get(`/user`)
      .then((response: AxiosResponse<User>) => {
        dispatch(setUser(response.data));
      })
      .catch((error) => {
        dispatch(logoutUser());
      });
  }, []);

  const requestOtp = useCallback(async (phone: string) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/users/otp-request`, { phone })
        .then((response) => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);

  const verifyOtp = useCallback(async (data: Test) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/users/otp-verify`, data)
        .then((response: AxiosResponse<LoginResponseData>) => {
          dispatch(setUserAndToken(response.data));
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);

  // const
  const logout = () => {
    dispatch(logoutUser());
    document.location.reload();
  };

  useEffect(() => {
    console.log("accessToken", accessToken);
    if (accessToken && !user?.id) fetchUser();
  }, []);

  return {
    user,
    accessToken,
    logout,
    verifyOtp,
    requestOtp,
  };
};
