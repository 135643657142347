import React, { useEffect } from 'react';
import './styles/global.scss';
import { TestLayout } from "./components/TestLayout/TestLayout";
import { useAppDispatch, useAppSelector } from './hooks/useApp';
import { useUser } from './hooks/useUser';

const App: React.FC = () => {
  // const dispatch = useAppDispatch();
  // useUser();
  //
  useEffect(() => {
    // dispatch(fetchStores());
    // dispatch(fetchCities());
  }, []);

  return (
      <TestLayout />
  );
}

export default App
